// extracted by mini-css-extract-plugin
export var description = "productsPage-module--description--bTfX3";
export var divider = "productsPage-module--divider--Zzkk2";
export var imageContainer = "productsPage-module--imageContainer--EUAt6";
export var info = "productsPage-module--info---+WHH";
export var seriesImages = "productsPage-module--seriesImages--VNjkv";
export var seriesImagesGrid = "productsPage-module--seriesImagesGrid--CNPcZ";
export var seriesImagesMany = "productsPage-module--seriesImagesMany--zz+zY";
export var seriesImagesOne = "productsPage-module--seriesImagesOne--y5HC0";
export var seriesImagesTwo = "productsPage-module--seriesImagesTwo--oCsGl";
export var title = "productsPage-module--title--cjm5h";