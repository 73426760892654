// extracted by mini-css-extract-plugin
export var hideOnMedium = "homepageSeriesProduct-module--hideOnMedium--ze7H1";
export var seriesImage = "homepageSeriesProduct-module--seriesImage--1L+NB";
export var seriesImageCaption = "homepageSeriesProduct-module--seriesImageCaption--iz5RX";
export var seriesImageContainer = "homepageSeriesProduct-module--seriesImageContainer--F+768";
export var seriesImageFull = "homepageSeriesProduct-module--seriesImageFull--bgLoq";
export var seriesImageHalves = "homepageSeriesProduct-module--seriesImageHalves--j90wC";
export var seriesImageMany = "homepageSeriesProduct-module--seriesImageMany--mXkgj";
export var seriesImageQuarters = "homepageSeriesProduct-module--seriesImageQuarters--JY3ut";
export var seriesImageThirds = "homepageSeriesProduct-module--seriesImageThirds--xoKoS";
export var showOnMedium = "homepageSeriesProduct-module--showOnMedium--YK2Tz";